import axios from "axios";
import {   useRef, useState } from "react";
import {
  apiBaseUrl,
  apiBaseUrl2,
  apiUrl,
} from "../important/commanFunction";
import {  toast } from "react-toastify";
import { useParams } from "react-router-dom";

const RegisterPageStep2 = (props) => {
  const { stepChange, formValue } = props;
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formInputValue, setFormInputValue] = useState({
    installationKey: formValue?.webInstallationKey,
    userFullName: formValue?.userFullName,
    userEmailAddress: formValue?.userEmailAddress,
    phoneNumber: formValue?.phoneNumber,
    countryCode: 91,
    accept:true
  });

  const inputRefs = useRef([]);

  const varifyOtp = () => {
    if (!formInputValue?.otp) {
      toast.error("Otp is required");
    } else if (formInputValue?.otp.length != 4) {
      toast.error("Please enter Valid Otp");
    }

    if (formInputValue?.otp && formInputValue?.otp.length === 4) {
      setLoading(true)
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };

      axios
        .post(
          apiBaseUrl2 + apiUrl.verifyEventEnrollmentOTP,
          {
            countryCode: 91,
            otp: formInputValue?.otp,
            phoneNumber: formInputValue?.phoneNumber?.slice(-10),
            userEmailAddress: formInputValue?.userEmailAddress,
            userFullName: formInputValue?.userFullName,
            enrollmentSourceId:id
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.verified == true) {
            if (
              response?.data?.businessAlreadyExists == true
            ) {
              stepChange(4);
            } else {
              stepChange(3);
            }
          } else {
            toast.error("Please enter Valid Otp");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Something went wrong please try again after sometime ");
        }).finally(() => setLoading(false));
    }
  };
  
  const valueChangeOtp = (e, index) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Only allow numbers 0-9

    // Update the input field with numeric value
    e.target.value = numericValue;

    // Move to the next input if the value is entered and valid
    if (numericValue.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Gather the OTP from all input fields
    const otp = inputRefs.current.map(ref => ref.value).join("");

    // Update the state and call inputChange function
    setFormInputValue({
      ...formInputValue,
      otp: otp,
    });
    // inputChange('otp', otp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      // Move focus to the previous input when Backspace is pressed and the current input is empty
      inputRefs.current[index - 1].focus();
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.userFullName) {
      errors.userFullName = "Name is required";
    } else if (data?.userFullName.length > 150) {
      errors.userFullName = "Name canNot be Greater than 150 char";
    }

    if (!data.accept) {
      errors.accept = "Accept the Terms of Service";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else {
      var expr = /^(0|91)?[6-9][0-9]{9}$/;
      if (!expr.test(data.phoneNumber.replace("+", ''))) {
        errors.phoneNumber = "Please enter valid phone number";
      }
    }

    return errors;
  };

  const otpSend = () => {

    const validationErrors = validateForm(formInputValue);
    if (Object.keys(validationErrors).length === 0) {
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };
      setLoading(true)

      axios
        .post(
          apiBaseUrl + apiUrl.sendOTPToWebUser,
          {
            phoneNumber: formInputValue?.phoneNumber?.slice(-10),
            countryCode: 91
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.responseType === "SUCCESS") {
            stepChange(2);
            toast.success("OTP sent successfully");
          } else {
            toast.error(
              "Something went wrong please try again after sometime "
            );
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error:", error);
          toast.error("Something went wrong please try again after sometime ");
        }).finally(() => {
          setLoading(false)
        });
    } else {
      for (const [key, value] of Object.entries(validationErrors)) {
        toast.error(value);
      }
    }
  };

  return (
    <>
      <div className={`row ${loading && "disabled"}`}>
      <form className="business-stepper-form row g-3">
              {Array(4).fill(0).map((_, index) => (
                <div className="col" key={index}>
                  <input
                    type="text"
                    className="form-control text-center"
                    maxLength="1"
                    placeholder="-"
                    ref={(el) => inputRefs.current[index] = el}  // Store the reference
                    onChange={(e) => valueChangeOtp(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
                  />
                </div>
              ))}

              <div className="col-12 text-end mt-3 mb-5">
                <a href="javaScript:void(0)" onClick={otpSend}>Resend OTP</a>
              </div>
              <div className="col-12">
                <a href="javaScript:void(0)" onClick={varifyOtp} className="btn btn-purple w-100">Verify OTP</a>
              </div>
            </form>
      </div>
    </>
  );
};

export default RegisterPageStep2;
