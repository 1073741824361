import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { apiBaseUrl, apiBaseUrl2, apiBaseUrl3, apiUrl, pageRoutes } from "../important/commanFunction";
import moment from "moment";
import { toast } from "react-toastify";
import DetailsPage from "./DetailsPage";

const Discussion = () => {
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState({});

    const hash = window.location.search;
    const tokenFromHash = hash.replace('?_iapprequestToken=', '') 

    const getDetails = () => {
        setLoading(true);
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        axios
            .post(
                apiBaseUrl3 + apiUrl.getDiscussion,
                {
                    "_inapprequesttoken": tokenFromHash,
                    "secureRequestVO": {
                        "cardId": 0,
                        "cardType": "BUSINESS",
                        "forumType": "BUSINESS_FORUM",
                        "userId": 0
                    }
                },
                {
                    headers: headers,
                }
            )
            .then((response) => {

                if (response?.data?.responseType === "SUCCESS") {
                    setDetails(response?.data?.discussionVO);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (tokenFromHash) {
            getDetails();
        }
    }, [tokenFromHash]);

    const [formInputValue, setFormInputValue] = useState({
        accept: true
    })

    const validateForm = (data) => {
        const errors = {};

        if (!data.message) {
            errors.message = "Message is required";
        } else if (data?.message.length > 150) {
        }

        if (!data.accept) {
            errors.accept = "Accept the Terms of Service";
        }

        return errors;
    };

    const addNewMessage = () => {
        const validationErrors = validateForm(formInputValue);
        if (Object.keys(validationErrors).length === 0) {
            const headers = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
            axios
                .post(
                    apiBaseUrl3 + apiUrl.addForumComment,
                    {
                        "_inapprequesttoken": tokenFromHash,
                        "senderName": details?.senderName,
                        "senderRole": details?.senderRole,
                        "comment": formInputValue?.message,
                        "secureRequestVO": {
                            "cardId": 0,
                            "cardType": "BUSINESS",
                            "forumType": "BUSINESS_FORUM",
                            "userId": 0
                        }
                    },
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    if (response?.data?.responseType === "SUCCESS") {
                        const formattedDate = moment().format('MMMM Do YYYY, h:mm:ss A');
                        const newComment = {
                            senderRole: details?.senderRole,
                            sentBy: details?.senderName,
                            sentDate: formattedDate,
                            text: formInputValue.message
                          };
                      
                          // Update the comments array by adding the new comment
                          setDetails(prevDiscussion => ({
                            ...prevDiscussion,
                            comments: [...prevDiscussion.comments, newComment]
                          }));

                        setFormInputValue({
                            ...formInputValue,
                            message: ''
                        })
                        toast.success("Comment successfully submited");
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong please try again after sometime");
                    console.error("Error:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            for (const [key, value] of Object.entries(validationErrors)) {
                toast.error(value);
            }
        }
    }

    return (
        <>
            {loading && (
                <div className="loading_main_div">
                    <div className="loader_base"></div>
                </div>
            )}
            {!loading && !details?.senderRole && (
                <div className="data_not_found">
                    <p>Record not found</p>
                </div>
            )}
            {!loading && details?.senderRole && (
                <>
                    <>
                        <section className="event-details mobile">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 title">
                                        <h1>{details?.discussionTitle}</h1>
                                        <span>{details?.discussionType?.toLowerCase()} Event</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="chat-discussion">
                                            <div className="chat-discussion-header">
                                                <h2>Discussion</h2>

                                                {/* <a href="" className="report">
                                                    <img width={32} src="/images/images/report.png" alt="Report Icon" />
                                                    <span>Report</span>
                                                </a> */}
                                                <img onClick={()=>getDetails()} width={32} src="/images/images/refresh-arrow.png" className="refresh-img"/>
                                            </div>
                                            <div>
                                                <div className="chat-items">
                                                    
                                                    {details?.comments?.map((res, index) =>
                                                        <div className="chat-item" key={index}>
                                                            <div className="chat-item-header">
                                                                <div className="col">
                                                                    <h5>{res?.sentBy}</h5>
                                                                    <span>{res?.senderRole}</span>
                                                                </div>
                                                                <span>{res?.sentDate}</span>
                                                            </div>
                                                            <div className="chat-body">
                                                                <p>{res?.text}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                <div className="chat-message">
                                                    <div className="chat-message-div">
                                                        <input type="text"
                                                            value={formInputValue?.message}
                                                            onChange={(e) => {
                                                                setFormInputValue({
                                                                    ...formInputValue,
                                                                    message: e.target.value
                                                                })
                                                            }}
                                                            className="form-control" placeholder="Type message..." />
                                                        <button onClick={() => addNewMessage()} className="send-btn">
                                                            <img width={32} src="/images/images/paper-plane.png" alt="Send Button" />
                                                        </button>
                                                    </div>
                                                    <div className="col-12 mt-2">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="gridCheck" checked={formInputValue?.accept} onChange={(e) => {
                                                                // setFormInputValue({
                                                                //     ...formInputValue,
                                                                //     accept: e.target.checked
                                                                // })
                                                            }} />
                                                            <label className="form-check-label" for="gridCheck">
                                                                I understand and agree to <Link to={pageRoutes.privacyPolicy}>privacy policy</Link> and <Link to={pageRoutes.termsPage}>terms and conditions</Link> and acknowledge that any harassment, abuse, obscenity and illegal content will be reported and is punishable to the fullest extent of law.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                </>
            )}
        </>
    );
}

export default Discussion