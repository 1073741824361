import { useState, useRef } from "react";
import {
  apiBaseUrl,
  isValidEmail,
  apiUrl,
} from "../important/commanFunction";
import axios from "axios";
import {  toast } from "react-toastify";

const RegisterPageStep1 = (props) => {
  const { stepChange, formValue, inputChange } = props;
  const [formInputValue, setFormInputValue] = useState({ accept: true });
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const valueChange = (e) => {
    if (!loading) {
      if (e.target.name === "phoneNumber" || e.target.name === "otp") {

        if (e.target.name === "phoneNumber") {
          let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
          if (sanitizedValue.startsWith('+')) {
            sanitizedValue = "+" + sanitizedValue.replaceAll('+', '');
          } else {
            sanitizedValue = sanitizedValue.replaceAll('+', '');
          }
          setFormInputValue({
            ...formInputValue,
            [e.target.name]: sanitizedValue,
          });
          inputChange(e.target.name, sanitizedValue);
        } else {
          const numericValue = e.target.value.replace(/[^0-9]/g, "");
          setFormInputValue({
            ...formInputValue,
            [e.target.name]: numericValue,
          });
          inputChange(e.target.name, numericValue);
        }
      } else {
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: e.target.value,
        });
        inputChange(e.target.name, e.target.value);
      }
    }
  };
  const inputRefs = useRef([]);

  const validateForm = (data) => {
    const errors = {};

    if (!data.userFullName) {
      errors.userFullName = "Name is required";
    } else if (data?.userFullName.length > 150) {
      errors.userFullName = "Name can Not be Greater than 150 char";
    }

    if (!data.accept) {
      errors.accept = "Accept the Terms of Service";
    }

    if (!data.emailAddress) {
      // errors.emailAddress = "Email is required";
    } else if (!isValidEmail(data?.emailAddress)) {
      errors.emailAddress = "Please enter valid email address";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else {
      var expr = /^(0|91)?[6-9][0-9]{9}$/;
      if (!expr.test(data.phoneNumber.replace("+", ''))) {
        errors.phoneNumber = "Please enter valid phone number";
      }
    }

    return errors;
  };

  const otpSend = () => {

    const validationErrors = validateForm(formInputValue);
    if (Object.keys(validationErrors).length === 0) {
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };
      setLoading(true)

      axios
        .post(
          apiBaseUrl + apiUrl.sendOTPToWebUser,
          {
            phoneNumber: formInputValue?.phoneNumber?.slice(-10),
            countryCode: 91
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.responseType === "SUCCESS") {
            setStep(2);
            toast.success("OTP sent successfully");
          } else {
            toast.error(
              "Something went wrong please try again after sometime "
            );
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error:", error);
          toast.error("Something went wrong please try again after sometime ");
        }).finally(() => {
          setLoading(false)
        });
    } else {
      for (const [key, value] of Object.entries(validationErrors)) {
        toast.error(value);
      }
    }
  };

  const varifyOtp = () => {
    if (!formInputValue?.otp) {
      toast.error("Otp is required");
    } else if (formInputValue?.otp.length != 4) {
      toast.error("Please enter Valid Otp");
    }

    if (formInputValue?.otp && formInputValue?.otp.length === 4) {
      setLoading(true)
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };

      axios
        .post(
          apiBaseUrl + apiUrl.verifyWebUserRegistrationOTP,
          {
            countryCode: 91,
            otp: formInputValue?.otp,
            phoneNumber: formInputValue?.phoneNumber?.slice(-10),
            userEmailAddress: formInputValue?.emailAddress,
            userFullName: formInputValue?.userFullName,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.verified == true) {
            if (
              response?.data?.businessAlreadyExists == true
            ) {
              stepChange(4);
            } else {
              inputChange(
                "webInstallationKey",
                response?.data?.webInstallationKey
              );
              stepChange(2);
            }
          } else {
            toast.error("Please enter Valid Otp");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Something went wrong please try again after sometime ");
        }).finally(() => setLoading(false));
    }
  };
  const valueChangeOtp = (e, index) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Only allow numbers 0-9

    // Update the input field with numeric value
    e.target.value = numericValue;

    // Move to the next input if the value is entered and valid
    if (numericValue.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Gather the OTP from all input fields
    const otp = inputRefs.current.map(ref => ref.value).join("");

    // Update the state and call inputChange function
    setFormInputValue({
      ...formInputValue,
      otp: otp,
    });
    inputChange('otp', otp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      // Move focus to the previous input when Backspace is pressed and the current input is empty
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
      <div className={` ${loading && 'disabled'}`} >
        {step === 1 && (
          <form className="business-stepper-form row g-3">
            <div className="col-12">
              <input type="text" className="form-control" id="name"
                name="userFullName"
                placeholder="Name"
                value={formInputValue?.userFullName || ""}
                onChange={valueChange}
                autoComplete="off" />
            </div>
            <div className="col-12">
              <input type="text" className="form-control" id="email"
                name="emailAddress"
                placeholder="Email"
                value={formInputValue?.emailAddress || ""}
                onChange={valueChange}
                autoComplete="off" />
            </div>
            <div className="col-12">
              <div className="form-control flag-input">
                <img src="/images/images/ind-icon.png" alt="India Flag" />
                <input type="number" id="phone" placeholder="Enter your mobile number"
                  autoComplete="off"
                  name="phoneNumber"
                  value={formInputValue?.phoneNumber || ""}
                  onChange={valueChange} />
              </div>
            </div>
            <div className="col-12 mt-5 mb-5">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="gridCheck" checked={formInputValue?.accept} onChange={(e) => {
                  setFormInputValue({
                    ...formInputValue,
                    accept: e.target.checked
                  })
                }} />
                <label className="form-check-label" for="gridCheck">
                  I have read the<a href="https://listy.city/privacy-policy" target="_blank">privacy policy</a> and
                  agree to <a href="https://listy.city/terms-of-use" target="_blank"> terms and conditions</a>.
                </label>
              </div>
            </div>
            <div className="col-12" onClick={otpSend}>
              <a href="javaScript:void(0)" className="btn btn-purple w-100">Send OTP</a>
            </div>
          </form>
        )}
        {step === 2 && (
          <>
            <form className="business-stepper-form row g-3">
              {Array(4).fill(0).map((_, index) => (
                <div className="col" key={index}>
                  <input
                    type="text"
                    className="form-control text-center"
                    maxLength="1"
                    placeholder="-"
                    ref={(el) => inputRefs.current[index] = el}  // Store the reference
                    onChange={(e) => valueChangeOtp(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
                  />
                </div>
              ))}

              <div className="col-12 text-end mt-3 mb-5">
                <a href="javaScript:void(0)" onClick={otpSend}>Resend OTP</a>
              </div>
              <div className="col-12">
                <a href="javaScript:void(0)" onClick={varifyOtp} className="btn btn-purple w-100">Verify OTP</a>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};
export default RegisterPageStep1;
